/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  Accordion,
  View,
  Button,
  Box,
  Text,
  Link,
  ProgressBar,
  Icon,
  Skeleton,
} from '@az/starc-ui';
import { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import {
  EMPTY_VALUE,
  MAX_PAGE_SIZE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  SORT_BY,
  SORT_ORDER,
} from '@shared/constants/constants';
import { Table } from '@shared/components/Table/Table';
import { DIRECTION, SortRowsParam } from '@shared/components/Table/Table.types';
import { Stat } from '@shared/components/Stat/Stat';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useSubzoneLayoutSearch } from '@inventory/services/hooks/useLayoutSearch';
import { mapWallToWallSubzoneRows } from '@inventory/utils/table/subzoneLayoutTableUtils';
import { useCycleCount, useGetPhaseProgress } from '@inventory/services/hooks/useCycleCounts';
import {
  CHOOSE_ASSIGNEE_CONTENT_TYPE,
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_STATUS_CD,
  DEFAULT,
  SUBZONE_ENTITY_CD,
  SINGLE_PAGE_SIZE,
  SUBZONE_ATTRIBUTE_KEY,
  USER_STATUS_CD,
  PAGE,
  SUBZONE_METRICS,
} from '@inventory/constants/constants';
import { checkCountTypeCd } from '@inventory/utils/utils';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { ENTITY_ASSOCIATIONS } from '@mdm/constants/constants';
import { DEFAULT_PAGE } from '@inbound/constants/constants';
import { WALL_TO_WALL_SUBZONES_TABLE_COLUMNS } from '@inventory/constants/tableColumns/wallToWallSubzone';
import { locationCountSearchType, SubZoneCycleCount, SubzoneType } from '@inventory/types/types';
import { useMutateSubZoneAssignees } from '@inventory/services/hooks/useMutateSubZoneAssignees';
import {
  StartCountParams,
  SubZoneAssigneesLocCountSyncParams,
} from '@inventory/types/service.types';
import {
  assignedUsersType,
  assigneeListType,
  attributeType,
  countLayoutSubZoneType,
  startCountPayloadDataType,
  WallToWallSubzoneRowType,
} from './Subzones.types';
import styles from './Subzones.module.scss';
import { useEndCount } from '@inventory/services/hooks/useEndCount';
import { useMutateStartCount } from '@inventory/services/hooks/useMutateStartCount';
import { PAGE_URLS } from '@shared/constants/routes';

const LazyLoadChooseAssigneesModal = lazy(() =>
  import('@inventory/components/CycleCountsModal/ChooseAssigneesModal/ChooseAssigneesModal').then(
    (module) => ({ default: module.ChooseAssigneesModal })
  )
);
import { Drawer } from '@shared/components/Drawer/Drawer';
import { Add, Checkmark, ExclamationTriangle } from '@az/starc-ui-icons';
import { Avatar } from '@shared/components/Avatar/Avatar';
import { ContentCard } from '@shared/components/ContentCard/ContentCard';
import { useMutateRemoveUser } from '@inventory/services/hooks/useMutateRemoveUser';
import { generateDateString, getValidPercentage } from '@shared/utils/commonUtils';
import { SZ_ASSIGNED_USR_DRAWER_TABLE_COLUMN } from '@inventory/constants/tableColumns/cycleCounts';

export const Subzones = () => {
  const paramData = useParams();
  const countTypeCdValue = paramData.countTypeCd || '';
  const countTypeCd = checkCountTypeCd(countTypeCdValue);
  const taskId = paramData.id || '';

  const [, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [, setSortBy] = useState<string>('');
  const [direction, setDirection] = useState<string>(DIRECTION.ASCENDING);
  // This status should be changed After start all phases of cycle count
  const [isSubZoneDetailsDrawerOpen, setIsSubZoneDetailsDrawerOpen] = useState(false);
  const [openAssigneeAccordian, setOpenAssigneeAccordian] = useState(true);
  const [subZoneDetailsId, setSubZoneDetailsId] = useState<{
    rowId: number | undefined;
    subZoneId: number | undefined;
  } | null>(null);
  const [subZoneDetails, setSubZoneDetails] = useState<SubZoneCycleCount | null>(null);
  const [isSubZoneDetailsLoading, setIsSubZoneDetailsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SortRowsParam[]>([]);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { handleNotification } = useNotificationHandler();
  const [showAssigneeModal, setShowAssigneeModal] = useState<boolean>(false);
  const [payloadCountLayoutSubZone, setPayloadCountLayoutSubZone] =
    useState<countLayoutSubZoneType>();
  const [, setHandleIndex] = useState<number>();
  const [handleLayoutDistinctName, setHandleLayoutDistinctName] = useState<string>();
  const [handleParentLayoutDistinctName, setHandleParentLayoutDistinctName] = useState<string>();
  // callback function to handle the selected assignees and location count data
  const [assigneeListPayloadData, setAssigneeListPayloadData] = useState<assigneeListType[]>([
    {
      userId: '',
      firstName: '',
      lastName: '',
      statusCd: '',
      layoutLocationCount: null,
      assignee: '',
    },
  ]);
  const [locationCountFlag, setLocationCountFlag] = useState<boolean>(true);
  const [slotRsrvTotalCount, setSlotRsrvTotalCount] = useState<number>();

  const [headerData, setHeaderData] = useState<locationCountSearchType[]>();
  const statusCd = (headerData && headerData[0].statusCd) || '';
  const year = (headerData && headerData[0]?.year) || '';
  const phase = (headerData && headerData[0]?.phase) || '';
  const [isAnyUserAssigned, setIsAnyUserAssigned] = useState<boolean>(false);
  const [userToRemove, setUserToRemove] = useState<string>('');
  const [existSubzoneAssignees, setExistSubzoneAssignees] = useState<assignedUsersType[]>(); // setSubZoneAssignees for send on choose assignee popup modal for edit count

  /* Query for get statusCd calling this API using countTypeCdValue & taskId*/
  const { wallToWallData: getHeaderComponentData } = useCycleCount({
    searchPage: {
      page: PAGE,
      size: SINGLE_PAGE_SIZE,
      sort: [
        {
          sortBy: SORT_BY.END_TS,
          direction: SORT_ORDER.DESC,
        },
      ],
    },
    searchCriteria: {
      countRequest: {
        countTypeCd: countTypeCd,
        taskId: taskId,
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
      ],
    },
  });

  useEffect(() => {
    if (Array.isArray(getHeaderComponentData?.content)) {
      if (getHeaderComponentData.content.length > 0) {
        setHeaderData(getHeaderComponentData.content);
      }
    }
  }, [getHeaderComponentData?.content]);

  /* Query for Layout Search data */
  const { subzoneLayoutSearchData } = useSubzoneLayoutSearch({
    searchPage: {
      page: DEFAULT_PAGE,
      size: MAX_PAGE_SIZE,
      sort: [
        {
          sortBy: SORT_BY.LAYOUT_NAME,
          direction: direction,
        },
      ],
    },
    searchCriteria: {
      entityCd: SUBZONE_ENTITY_CD,
      entityTypeCd: DEFAULT,
      entityAssociations: [ENTITY_ASSOCIATIONS.LAYOUT_ATTRIBUTES],
    },
  });

  const isRelevantStatus = [
    CYCLE_COUNT_STATUS_CD.INPROGRESS,
    CYCLE_COUNT_STATUS_CD.COMPLETE,
    CYCLE_COUNT_STATUS_CD.NEW,
  ].includes(statusCd);

  /* Query for Location-count Search data */
  const { wallToWallData: subzonesCycleCountData } = useCycleCount(
    {
      searchPage: {
        page: DEFAULT_PAGE,
        size: SINGLE_PAGE_SIZE,
        sort: [
          {
            sortBy: SORT_BY.END_TS,
            direction: SORT_ORDER.DESC,
          },
        ],
      },
      searchCriteria: {
        countRequest: {
          countTypeCd: countTypeCd,
          statusCd: [statusCd],
          taskId: taskId,
        },
        entitiyAssociations: [
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_LOCATION,
        ],
      },
    },
    isRelevantStatus
  );

  const {
    data: locationsData,
    refetch: fetchLocationDetails,
    isFetching: isFetchingLocations,
    isError: isFetchingLocationsError,
  } = useGetPhaseProgress(
    {
      countTypeCd: countTypeCd,
      taskId: taskId || '',
      parentLayoutName:
        subZoneDetailsId &&
        subZoneDetailsId.rowId != undefined &&
        subZoneDetailsId.subZoneId != undefined &&
        !isNaN(subZoneDetailsId.rowId) &&
        !isNaN(subZoneDetailsId.subZoneId) &&
        subzonesCycleCountData
          ? subzonesCycleCountData?.content[subZoneDetailsId.rowId].subzones?.[
              subZoneDetailsId.subZoneId
            ].parentLayoutName || ''
          : '',
      metrics: [SUBZONE_METRICS],
    },
    false
  );

  /* Query for perform-action-sync data */
  const { mutateEndCount } = useEndCount();
  const { mutateStartCount } = useMutateStartCount();
  const { mutateUser, isLoading: isRemovingUser } = useMutateRemoveUser();

  /* Query for assign Users and Counts with location count command handler perform-action-sync API */
  const { isLoading: isMutateSubzoneAssignee, mutateSubZoneAssignees } =
    useMutateSubZoneAssignees();

  /* Functions */
  const onCancelClick = (status: string) => {
    const currentTimestamp = new Date().toISOString();

    mutateEndCount(
      {
        countRequest: {
          taskId: taskId,
          countTypeCd: countTypeCd,
          statusCd: status,
          endTs: currentTimestamp,
        },
      },
      {
        onSuccess: () => {
          handleNotification(
            NOTIFICATION_TYPES.SUCCESS,
            t('CycleCount.cancel.successNotification', {
              countTypeCd: `${checkCountTypeCd(countTypeCd)}`,
              year: `${year}`,
              phase: `${phase}`,
            })
          );
          navigate(PAGE_URLS.CYCLE_COUNTS);
        },
      }
    );
  };

  /* On Click of Start Count bottom button execute below function */
  const handleStartCount = useCallback(
    (status: string) => {
      const newCountLayoutSubZoneData: startCountPayloadDataType[] = [];

      subzonesCycleCountData?.content?.forEach((data) =>
        data.subzones?.forEach((subZone) => {
          const {
            parentLayoutDistinctName,
            parentLayoutName,
            parentMapSequence,
            totalFpsLayoutCountExpected,
            totalRsrvLayoutCountExpected,
          } = subZone;
          newCountLayoutSubZoneData.push({
            countRequest: {
              taskId: taskId,
              countTypeCd: countTypeCd,
              statusCd: status,
              startTs: new Date().toISOString(),
            },
            countLayoutSubzone: {
              parentLayoutDistinctName: parentLayoutDistinctName || '',
              parentLayoutName: parentLayoutName || '',
              parentMapSequence: parentMapSequence || null,
              startTs: new Date().toISOString(),
              fpsChildCount: totalFpsLayoutCountExpected || null,
              rsrvChildCount: totalRsrvLayoutCountExpected || null,
            },
          });
        })
      );

      if (newCountLayoutSubZoneData.length > 0) {
        const payload: { UpdateLocationCountRequest: StartCountParams } = {
          UpdateLocationCountRequest: newCountLayoutSubZoneData as StartCountParams,
        };
        mutateStartCount(payload, {
          onSuccess: (data) => {
            if (data) {
              queryClient.invalidateQueries(['wallToWall']); // refetch the getHeaderComponentData
              const successMessage = t('CycleCount.Notification.UpdateStartCount.Success');
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            }
          },
          onError: (error) => {
            const errorMessage = t('CycleCount.Notification.UpdateStartCount.Error');
            error ?? handleNotification(NOTIFICATION_TYPES.ERROR, errorMessage);
          },
        });
      }
    },
    [
      subzonesCycleCountData?.content,
      countTypeCd,
      handleNotification,
      mutateStartCount,
      queryClient,
      t,
      taskId,
    ]
  );

  /* Onclick of specific row of subZone handle below function for Assign users and counts */
  const handleMultipleAssignee = (
    index: number,
    layoutDistinctName: string,
    parentLayoutDistinctName: string
  ) => {
    const targetLayoutName = `${layoutDistinctName}${parentLayoutDistinctName}`;
    const filteredUsers = filterAssignedUsers(targetLayoutName);
    setExistSubzoneAssignees(filteredUsers);

    setShowAssigneeModal(true);
    setHandleIndex(index);
    setHandleLayoutDistinctName(layoutDistinctName);
    setHandleParentLayoutDistinctName(parentLayoutDistinctName);
  };

  // Function to filter Subzone Assigned User Data
  const filterAssignedUsers = (targetLayoutName: string) => {
    const filteredUsers: assignedUsersType[] = [];
    subzonesCycleCountData?.content?.forEach((item) => {
      item?.subzones?.forEach((subzone) => {
        const result = subzone.assignedUsers?.filter(
          (item) =>
            item.parentLayoutDistinctName === targetLayoutName &&
            item.userStatusCd === USER_STATUS_CD.ASSIGNED
        );

        result?.map((user) =>
          filteredUsers.push({
            parentLayoutDistinctName: user.parentLayoutDistinctName,
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            userStatusCd: user.userStatusCd,
            layoutLocationsAssigned: user.layoutLocationsAssigned || 0,
          })
        );
      });
    });

    return filteredUsers;
  };

  /* Hook */
  useEffect(() => {
    let hasAssignedUsers = false;
    const locationCountData: WallToWallSubzoneRowType[] = [];
    const processedSubzones = new Set<string>();

    subzonesCycleCountData?.content?.forEach((item, index) => {
      item?.subzones?.forEach((subzone, subzoneIndex) => {
        const subzoneKey = subzone.parentLayoutDistinctName || '';
        processedSubzones.add(subzoneKey);

        const assignedUsers = subzone.assignedUsers || [];
        if (assignedUsers.length > 0) {
          hasAssignedUsers = true;
        }
        locationCountData.push({
          statusCd: item.statusCd,
          index: index,
          subzone: subzone?.parentLayoutName || EMPTY_VALUE,
          timeStarted:
            subzone?.startTs !== null
              ? generateDateString(new Date(subzone.startTs || ''), t('DateFormat.ShortTime'))
              : EMPTY_VALUE,
          counter:
            subzone.assignedUsers
              ?.filter(
                (assignee) =>
                  assignee.userStatusCd != USER_STATUS_CD.UNASSIGNED &&
                  assignee.firstName !== null &&
                  assignee.lastName !== ''
              )
              .map((assignee) => `${assignee.firstName} ${assignee.lastName}`) || [],
          reCounter: EMPTY_VALUE,
          netAdjusted:
            subzone.totalInvAmtExpected && subzone.totalInvAmtCounted
              ? getValidPercentage((subzone.totalInvAmtCounted * 100) / subzone.totalInvAmtExpected)
              : EMPTY_VALUE,
          absolutePCAccuracy:
            subzone.totalInvAmtCounted && subzone.totalInvAmtExpected
              ? getValidPercentage((subzone.totalInvAmtCounted * 100) / subzone.totalInvAmtExpected)
              : EMPTY_VALUE,
          pcAdjustments: EMPTY_VALUE,
          variances: subzone.totalInvAmtExceedsVariance || EMPTY_VALUE,
          locationProgress: getLocationProgressValues(subzone),
          totalLocations:
            subzone.totalFpsLayoutCountExpected && subzone.totalRsrvLayoutCountExpected
              ? subzone.totalFpsLayoutCountExpected + subzone.totalRsrvLayoutCountExpected
              : EMPTY_VALUE,
          locationsAssigned: subzone.layoutLocationsAssigned || EMPTY_VALUE,
          locationsCompleted:
            subzone.totalFpsLayoutCountCompleted && subzone.totalRsrvLayoutCountCompleted
              ? subzone.totalFpsLayoutCountCompleted + subzone.totalRsrvLayoutCountCompleted
              : EMPTY_VALUE,
          totalFpsLayoutCountExpected: subzone.totalFpsLayoutCountExpected || null,
          totalRsrvLayoutCountExpected: subzone.totalRsrvLayoutCountExpected || null,
          parentLayoutDistinctName: subzone.parentLayoutDistinctName || EMPTY_VALUE,
          itemIndex: index,
          subZoneIndex: subzoneIndex,
        });
      });
    });

    // Process data from `subzoneLayoutSearchData` if the subzone is not already in the Set
    subzoneLayoutSearchData?.content.forEach((item, index) => {
      const subzoneKey = item.layout.layoutDistinctName || '';
      if (!processedSubzones.has(subzoneKey)) {
        locationCountData.push({
          statusCd: statusCd,
          index: index,
          subzone: item.layout.layoutName || EMPTY_VALUE,
          timeStarted: EMPTY_VALUE,
          counter: [],
          reCounter: EMPTY_VALUE,
          netAdjusted: EMPTY_VALUE,
          absolutePCAccuracy: EMPTY_VALUE,
          pcAdjustments: EMPTY_VALUE,
          variances: EMPTY_VALUE,
          locationProgress: null,
          totalLocations: EMPTY_VALUE,
          locationsAssigned: EMPTY_VALUE,
          locationsCompleted: EMPTY_VALUE,
          layoutDistinctName: item.layout.layoutDistinctName || EMPTY_VALUE,
          itemIndex: index,
        });
      }
    });
    // Set the combined data
    setTableData(
      mapWallToWallSubzoneRows(locationCountData, handleMultipleAssignee, getSubZoneDetails)
    );
    setIsAnyUserAssigned(hasAssignedUsers);
  }, [statusCd, subzoneLayoutSearchData, subzonesCycleCountData, t]);

  const getLocationProgressValues = (subzone: SubzoneType | undefined) => {
    let minValue = 0;
    let maxValue = 0;
    if (subzone) {
      if (subzone.totalFpsLayoutCountCompleted && subzone.totalRsrvLayoutCountCompleted) {
        minValue = subzone.totalFpsLayoutCountCompleted + subzone.totalRsrvLayoutCountCompleted;
      }

      if (subzone.totalFpsLayoutCountExpected && subzone.totalRsrvLayoutCountExpected) {
        maxValue = subzone.totalFpsLayoutCountExpected + subzone.totalRsrvLayoutCountExpected;
      }
      return { min: minValue, max: maxValue };
    } else {
      return { min: minValue, max: maxValue };
    }
  };

  const getSubZoneDetails = (
    _event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    _rowId: number | undefined,
    _subZone: number | undefined
  ) => {
    _event.stopPropagation();
    setSubZoneDetailsId({ rowId: _rowId, subZoneId: _subZone });
    setIsSubZoneDetailsLoading(true);
    setIsSubZoneDetailsDrawerOpen(true);
  };

  const handleTableRowClick = (clickedRow: SortRowsParam) => {
    const rowIds = clickedRow.id.split('-');
    setSubZoneDetailsId({
      rowId: Number(rowIds[0]),
      subZoneId: rowIds[1] ? Number(rowIds[1]) : undefined,
    });
    setIsSubZoneDetailsLoading(true);
    setIsSubZoneDetailsDrawerOpen(true);
  };

  /* After assigned subZone's assignees and location count clicked on save button then execute this function */
  const onClickSaveSubZoneAssignee = () => {
    const assignedUserPayload: assigneeListType[] = [...assigneeListPayloadData];

    const subZoneAssigneesData = assignedUserPayload.map(
      (user) =>
        user.userId &&
        user.layoutLocationCount && {
          countRequest: {
            countTypeCd: countTypeCd,
            statusCd: CYCLE_COUNT_STATUS_CD.NEW,
            taskId: taskId,
          },

          countLayoutSubzone: payloadCountLayoutSubZone,
          user: {
            userId: user.userId ? user.userId : '',
            firstName: user.firstName ? user.firstName : '',
            lastName: user.lastName ? user.lastName : '',
            statusCd: user.statusCd ? user.statusCd : USER_STATUS_CD.ASSIGNED,
            layoutLocationCount: user.layoutLocationCount ? user.layoutLocationCount : null,
          },
        }
    );

    const hasNullIndex = subZoneAssigneesData.some(
      (user) => user === null || user === undefined || user.toString() === ''
    );

    if (hasNullIndex) {
      const errorMessage = t('CycleCount.Notification.AssigneeMsg');
      handleNotification(NOTIFICATION_TYPES.ERROR, errorMessage);
      return;
    }

    const payload: { AssignSubZoneLocCountRequest: SubZoneAssigneesLocCountSyncParams } = {
      AssignSubZoneLocCountRequest: subZoneAssigneesData as SubZoneAssigneesLocCountSyncParams,
    };
    {
      subZoneAssigneesData &&
        mutateSubZoneAssignees(payload, {
          onSuccess: (data) => {
            if (data && !isMutateSubzoneAssignee) {
              queryClient.invalidateQueries(['wallToWall']);
              const successMessage = t('CycleCount.Notification.UpdateSubzoneAssignees.Success');
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            }
            queryClient.invalidateQueries(['wallToWall']);
            setIsSubZoneDetailsDrawerOpen(false);
            setShowAssigneeModal(false);
          },
        });
    }
  };

  const removeAssignee = (assigneeId: number) => {
    if (
      subZoneDetailsId?.rowId != undefined &&
      subZoneDetailsId?.subZoneId != undefined &&
      subzonesCycleCountData?.content[subZoneDetailsId.rowId].subzones
    ) {
      setUserToRemove(assigneeId.toString());
      const subzone =
        subzonesCycleCountData?.content[subZoneDetailsId.rowId].subzones?.[
          subZoneDetailsId.subZoneId
        ];

      const taskData = subzonesCycleCountData?.content[subZoneDetailsId.rowId];

      const user = subzone?.assignedUsers
        ? subzone?.assignedUsers.filter(
            (assignee) => assignee.userStatusCd != USER_STATUS_CD.UNASSIGNED
          )[assigneeId]
        : null;

      mutateUser(
        {
          countRequest: {
            taskId: taskId || '',
            countTypeCd: taskData.countTypeCd || '',
            statusCd: taskData.statusCd || '',
          },
          countLayoutSubzone: {
            parentLayoutDistinctName: subzone?.parentLayoutDistinctName || '',
            parentLayoutName: subzone?.parentLayoutName || '',
          },
          user: {
            userId: user?.userId || '',
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            statusCd: USER_STATUS_CD.UNASSIGNED,
          },
        },
        {
          onSuccess: () => {
            setUserToRemove('');
          },
          onError: () => {
            setUserToRemove('');
          },
        }
      );
    }
  };

  /* Hook - fetch sub zone drawer details */
  useEffect(() => {
    if (subZoneDetailsId != null) {
      if (subZoneDetailsId.rowId != undefined && subZoneDetailsId.subZoneId === undefined) {
        const mappedData: SubZoneCycleCount = {
          subZoneName:
            subzoneLayoutSearchData?.content[subZoneDetailsId.rowId].layout.layoutName ||
            EMPTY_VALUE,
          startDate: EMPTY_VALUE,
          Assignee: [],
          netDollars: EMPTY_VALUE,
          pieceAccuracy: EMPTY_VALUE,
          locationProgress: { min: 0, max: 0 },
          locations: null,
          layoutName:
            subzoneLayoutSearchData?.content[subZoneDetailsId.rowId].layout.layoutDistinctName ||
            '',
          parentLayoutName:
            subzoneLayoutSearchData?.content[subZoneDetailsId.rowId].parent?.layoutDistinctName ||
            '',
        };
        setSubZoneDetails(mappedData);
        setIsSubZoneDetailsLoading(false);
      } else {
        if (subZoneDetailsId.rowId != undefined && subZoneDetailsId.subZoneId != undefined) {
          fetchLocationDetails();
        }
      }
    }
  }, [
    fetchLocationDetails,
    statusCd,
    subZoneDetailsId,
    subzoneLayoutSearchData?.content,
    subzonesCycleCountData,
  ]);

  /* Hook - set sub zone drawer details */
  useEffect(() => {
    if (locationsData && !isFetchingLocations) {
      if (
        subZoneDetailsId?.rowId != undefined &&
        subZoneDetailsId?.subZoneId != undefined &&
        subzonesCycleCountData?.content[subZoneDetailsId.rowId].subzones
      ) {
        const subzone =
          subzonesCycleCountData?.content[subZoneDetailsId.rowId].subzones?.[
            subZoneDetailsId.subZoneId
          ];

        let totalVariance = 0;
        let totalPieceAdjustment = 0;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let locationDetails = locationsData?.szLocationVariances?.map((location: any) => {
          totalVariance += location.totalInvAmtExceedsVariance;
          totalPieceAdjustment += location.totalInvQtyExceedsVariance;
          return {
            locationName: location.layoutName,
            assignee: location.firstName + ' ' + location.lastName,
            variance: location.totalInvAmtExceedsVariance,
            pieceAdjustment: location.totalInvQtyExceedsVariance,
            isChecked:
              location.countStatusCd === CYCLE_COUNT_STATUS_CD.COMPLETE ||
              location.countStatusCd === CYCLE_COUNT_STATUS_CD.INPROGRESS ||
              location.countStatusCd === CYCLE_COUNT_STATUS_CD.PARTIAL,
          };
        });
        if (locationDetails) {
          locationDetails = [
            ...locationDetails,
            ...[
              {
                locationName: 'Total',
                assignee: '',
                variance: totalVariance,
                pieceAdjustment: totalPieceAdjustment,
                isChecked: false,
              },
            ],
          ];
        }

        const mappedData: SubZoneCycleCount = {
          subZoneName: subzone?.parentLayoutName || '',
          startDate: subzone?.startTs || '',
          Assignee:
            subzone?.assignedUsers
              ?.filter((assignee) => assignee.userStatusCd != USER_STATUS_CD.UNASSIGNED)
              .map((assignee, assigneeIndex) => ({
                id: assigneeIndex,
                name: `${assignee.firstName} ${assignee.lastName}`,
              })) || [],
          netDollars:
            subzone?.totalInvAmtExpected && subzone.totalInvAmtCounted
              ? (subzone.totalInvAmtCounted - subzone.totalInvAmtExpected).toString()
              : EMPTY_VALUE,
          pieceAccuracy:
            subzone?.totalInvAmtCounted && subzone.totalInvAmtExpected
              ? ((subzone.totalInvAmtCounted * 100) / subzone.totalInvAmtExpected).toString()
              : EMPTY_VALUE,
          locationProgress: getLocationProgressValues(subzone),
          layoutName: '',
          parentLayoutName: subzone?.parentLayoutDistinctName || '',
          locations: locationDetails,
        };
        setSubZoneDetails(mappedData);
        setIsSubZoneDetailsLoading(false);
      }
    }
  }, [isFetchingLocations]);

  /* Hook  - Check SLOT & RSRV & FPS Count with assigned location count*/
  useEffect(() => {
    const isExistAssignee = existSubzoneAssignees?.length;
    isExistAssignee && isExistAssignee > 0
      ? setLocationCountFlag(false)
      : setLocationCountFlag(true);

    if (slotRsrvTotalCount !== undefined) {
      const sumOfLocationCount = assigneeListPayloadData.reduce((total, assignee) => {
        return total + (assignee.layoutLocationCount ? assignee.layoutLocationCount : 0);
      }, 0);

      if (sumOfLocationCount === 0 || sumOfLocationCount < 0) {
        setLocationCountFlag(true);
      } else if (sumOfLocationCount > slotRsrvTotalCount) {
        setLocationCountFlag(true);
      } else {
        setLocationCountFlag(false);
      }
    }
  }, [assigneeListPayloadData, existSubzoneAssignees?.length, slotRsrvTotalCount]);

  /*  Hook - Filter Location count API with assign Users and counts Build Payload */
  useEffect(() => {
    const locationCountAPIData = subzonesCycleCountData?.content
      ?.flatMap((data) => data.subzones)
      ?.find((subzone) => subzone?.parentLayoutDistinctName === handleParentLayoutDistinctName);

    if (locationCountAPIData) {
      const {
        parentLayoutDistinctName,
        parentLayoutName,
        parentMapSequence,
        totalFpsLayoutCountExpected,
        totalRsrvLayoutCountExpected,
      } = locationCountAPIData;
      const newCountLayoutSubZoneData: countLayoutSubZoneType = {
        parentLayoutDistinctName: parentLayoutDistinctName || '',
        parentLayoutName: parentLayoutName || '',
        parentMapSequence: parentMapSequence || null,
        startTs: new Date().toISOString(),
        fpsChildCount: totalFpsLayoutCountExpected || null,
        rsrvChildCount: totalRsrvLayoutCountExpected || null,
      };
      setPayloadCountLayoutSubZone(newCountLayoutSubZoneData);

      setSlotRsrvTotalCount(
        Number(locationCountAPIData.totalFpsLayoutCountExpected) +
          Number(locationCountAPIData.totalRsrvLayoutCountExpected)
      );
    }
  }, [subzonesCycleCountData?.content, handleParentLayoutDistinctName]);

  /* Filter the layout data by layoutKey and calculate the sum of SLOT_COUNT and RSRV_COUNT cycleCountPhaseData*/
  useEffect(() => {
    const filteredLayoutData = subzoneLayoutSearchData?.content
      ?.flatMap((data) => data.layout)
      ?.find((layout) => layout?.layoutDistinctName === handleLayoutDistinctName);

    if (filteredLayoutData) {
      const slotCount =
        filteredLayoutData?.attributes?.find(
          (attr: attributeType) => attr.name === SUBZONE_ATTRIBUTE_KEY.SLOT_COUNT
        )?.value || null;
      const rsrvCount =
        filteredLayoutData?.attributes?.find(
          (attr: attributeType) => attr.name === SUBZONE_ATTRIBUTE_KEY.RSRV_COUNT
        )?.value || null;
      setSlotRsrvTotalCount(Number(slotCount) + Number(rsrvCount));
      /* when Create NEW Phase with SubZone layout API having statusCd as NEW */
      if (statusCd === CYCLE_COUNT_STATUS_CD.NEW) {
        const { layoutDistinctName, layoutName, mapSequence } = filteredLayoutData;
        const newCountLayoutSubZoneData: countLayoutSubZoneType = {
          parentLayoutDistinctName: layoutDistinctName || '',
          parentLayoutName: layoutName || '',
          parentMapSequence: mapSequence || null,
          startTs: new Date().toISOString(),
          fpsChildCount: Number(slotCount),
          rsrvChildCount: Number(rsrvCount),
        };
        setPayloadCountLayoutSubZone(newCountLayoutSubZoneData);
      }
    }
  }, [subzoneLayoutSearchData?.content, handleLayoutDistinctName, statusCd]);

  return (
    <>
      {showAssigneeModal && (
        <Suspense>
          <LazyLoadChooseAssigneesModal
            showModal={showAssigneeModal}
            contentType={CHOOSE_ASSIGNEE_CONTENT_TYPE.MULTIPLE_ASSIGNEE}
            onClose={() => setShowAssigneeModal(false)}
            onClickSaveSubZoneAssignee={onClickSaveSubZoneAssignee}
            setAssigneeListPayloadData={setAssigneeListPayloadData}
            locationCountFlag={locationCountFlag}
            existAssigneeData={existSubzoneAssignees}
          />
        </Suspense>
      )}
      <View className={styles['subzone-view-screen']}>
        <View
          direction="row"
          justify="end"
          align="end"
          padding={[4, 6]}
          className={styles['subzone-view-screen__statistics-section__statistics']}
        >
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.LocationsCounted')}
            primaryText="31.79%"
            secondaryTextProps={{
              secondaryText: '700 / 2,025',
            }}
            width="220px"
            size="medium"
          />
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.SlotsCounted')}
            primaryText="39.88%"
            secondaryTextProps={{
              secondaryText: '650 / 1,630',
            }}
            width="220px"
            size="medium"
          />
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.RSRVCounted')}
            primaryText="69.54%"
            secondaryTextProps={{
              secondaryText: '280 / 455',
            }}
            width="220px"
            size="medium"
          />
        </View>

        <View gap={2} className={styles['subzone-view-screen__table-view']}>
          {tableData?.length > 0 ? (
            <Table
              columns={WALL_TO_WALL_SUBZONES_TABLE_COLUMNS}
              rows={tableData}
              isPaginated={false}
              isCheckboxDisabled={false}
              isApiLoadedData={true}
              pageSize={PAGE_SIZE}
              defaultPage={DEFAULT_PAGE}
              isCreditItem={false}
              isCheckboxTable={false}
              totalPages={Math.ceil(tableData?.length / PAGE_SIZE)}
              styleVariant={TableStylingVariants.DETAILS}
              onSort={(_sorting, _columnId) => {
                _sorting.forEach((sorting) => {
                  if (sorting.id === _columnId) {
                    setSortBy(_columnId);
                    setDirection(sorting.direction);
                  }
                });
              }}
              onPageChange={(_pageNumber) => {
                setCurrentPage(_pageNumber);
              }}
              onRowAction={(clickedRow) => handleTableRowClick(clickedRow)}
            />
          ) : (
            <View gap={4} className={styles['subzone__no-tasks']}>
              <View justify="center" align="center">
                <View width="360px">
                  <EmptyState
                    svg={Box}
                    subtitle={t(`CycleCount.NoSubzonesFound`)}
                    text={t('CycleCount.NoSubzonesFoundText', {
                      countTypeCd: checkCountTypeCd(countTypeCd),
                    })}
                  />
                </View>
              </View>
            </View>
          )}
        </View>
      </View>
      {statusCd !== CYCLE_COUNT_STATUS_CD.INPROGRESS &&
        statusCd !== CYCLE_COUNT_STATUS_CD.COMPLETE && (
          <View gap={3} direction="row" padding={[2, 6, 4, 6]} justify="end">
            <View.Item>
              <Button
                variant="secondary"
                size="large"
                onClick={() => onCancelClick(CYCLE_COUNT_STATUS_CD.COMPLETE)}
              >
                {t(`CycleCount.ChooseAssigneesModal.Cancel`)}
              </Button>
            </View.Item>
            <View.Item gapBefore={6}>
              <Button
                size="large"
                onClick={() => handleStartCount(CYCLE_COUNT_STATUS_CD.INPROGRESS)}
                disabled={!isAnyUserAssigned}
              >
                {t(`CycleCount.ChooseAssigneesModal.StartCount`)}
              </Button>
            </View.Item>
          </View>
        )}
      <Drawer
        show={isSubZoneDetailsDrawerOpen}
        handleClose={() => {
          setIsSubZoneDetailsDrawerOpen(false);
          setSubZoneDetailsId(null);
          setIsSubZoneDetailsLoading(false);
        }}
        size="small"
        position="right"
        headerTitle={t('CycleCount.SubZoneTitle', {
          subzone: subZoneDetails?.subZoneName,
        })}
        headerDescription={t('CycleCount.SubZoneStatus', {
          time: subZoneDetails?.startDate,
        })}
        childrenAlignment={false}
        removeInnerPadding={true}
        headerSkeleton={isSubZoneDetailsLoading}
      >
        {isFetchingLocationsError ? (
          <View
            backgroundColor="secondary"
            height="100%"
            padding={5}
            justify="center"
            align="center"
          >
            <Icon svg={ExclamationTriangle} />
            <Text>Unable to retrieve subzone details. Please try again later</Text>
          </View>
        ) : isSubZoneDetailsLoading || isFetchingLocations ? (
          <View gap={5} padding={5}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </View>
        ) : (
          <View backgroundColor="secondary" height="100%">
            <Accordion
              open={openAssigneeAccordian}
              disabled={statusCd === CYCLE_COUNT_STATUS_CD.COMPLETE}
              onToggle={(updatedOpen) => setOpenAssigneeAccordian(updatedOpen)}
              headerOptions={{
                headerElement: (
                  <View direction="row" align="center" justify="space-between" padding={[0, 4]}>
                    <Text variant="display-4">{t('CycleCount.AssigneeTitle')}</Text>
                    <Link
                      startIcon={Add}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMultipleAssignee(
                          subZoneDetailsId?.rowId || 0,
                          subZoneDetails?.layoutName || '',
                          subZoneDetails?.parentLayoutName || ''
                        );
                      }}
                    >
                      {t('PODashboard.Assignees.AddAssignee')}
                    </Link>
                  </View>
                ),
              }}
            >
              {subZoneDetails?.Assignee.length ? (
                subZoneDetails?.Assignee.map((assignee) => (
                  <View direction="row" align="center" gap={4}>
                    <View.Item grow>
                      <Avatar
                        name={assignee.name}
                        size="large"
                        variant="assignee"
                        showText={true}
                      />
                    </View.Item>

                    <View.Item>
                      <Button
                        variant="pill"
                        onClick={() => removeAssignee(assignee.id)}
                        loading={isRemovingUser && userToRemove === assignee.id.toString()}
                      >
                        {t('PODashboard.Actions.Remove')}
                      </Button>
                    </View.Item>
                  </View>
                ))
              ) : (
                <View direction="row" align="center" justify="center" backgroundColor="accent">
                  <Text>{t('TopVariances.NoSubZoneAssignee')}</Text>
                </View>
              )}
            </Accordion>
            <View direction="row" gap={2} padding={4}>
              <View.Item grow>
                <ContentCard
                  title={t('CycleCount.DollarsAdjusted')}
                  startContent={subZoneDetails?.netDollars}
                  variant="primary"
                />
              </View.Item>
              <View.Item grow>
                <ContentCard
                  title={t('CycleCount.AbsoluteAccuracy')}
                  startContent={subZoneDetails?.pieceAccuracy}
                  variant="primary"
                />
              </View.Item>
            </View>
            <View padding={4} gap={2}>
              <Text color="500" variant="display-5">
                {t('CycleCount.WallToWallPhases.Subzone.Stats.LocationsCounted')}
              </Text>
              <View direction="row" align="end" justify="space-between">
                <Text variant="display-3">
                  {subZoneDetails?.locationProgress?.min && subZoneDetails?.locationProgress?.max
                    ? (subZoneDetails?.locationProgress?.min /
                        subZoneDetails?.locationProgress?.max) *
                        100 +
                      ' %'
                    : 0 + ' %'}
                </Text>
                <Text color="500" variant="subtitle-regular">
                  {subZoneDetails?.locationProgress?.min} / {subZoneDetails?.locationProgress?.max}
                </Text>
              </View>

              <ProgressBar
                max={
                  subZoneDetails?.locationProgress?.max ? subZoneDetails?.locationProgress?.max : 0
                }
                value={
                  subZoneDetails?.locationProgress?.min ? subZoneDetails?.locationProgress?.min : 0
                }
                label=""
                barColor="100"
                valueColor="accent"
                attributes={{ style: { width: '100%' } }}
              />
            </View>
            <View padding={4}>
              <Table
                columns={SZ_ASSIGNED_USR_DRAWER_TABLE_COLUMN}
                rows={
                  subZoneDetails && subZoneDetails.locations
                    ? subZoneDetails.locations.map((location, locIndex) => {
                        return {
                          id: locIndex.toString(),
                          cells: [
                            {
                              value: (
                                <View direction="row" align="center" gap={2}>
                                  {location.isChecked && <Icon svg={Checkmark} />}
                                  <Text>{location.locationName}</Text>
                                </View>
                              ),
                            },
                            {
                              value: location.assignee ? (
                                <Avatar
                                  name={location.assignee || ''}
                                  size="large"
                                  variant="assignee"
                                  showText={false}
                                />
                              ) : (
                                <></>
                              ),
                            },
                            { value: location.variance },
                            { value: location.pieceAdjustment },
                          ],
                        };
                      })
                    : []
                }
                isPaginated={false}
                isCheckboxDisabled={false}
                pageSize={0}
                defaultPage={0}
                isCreditItem={false}
                isCheckboxTable={false}
                styleVariant={TableStylingVariants.DETAILS}
                totalPages={0}
                onSort={() => ({})}
              />
            </View>
          </View>
        )}
      </Drawer>
    </>
  );
};
