/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Actionable, Text, View } from '@az/starc-ui';
import { ProgressBar } from '@inbound/components/ProgressBar/ProgressBar';
import { CYCLE_COUNT_STATUS_CD } from '@inventory/constants/constants';
import { WallToWallSubzoneRowType } from '@inventory/pages/CycleCounts/components/Subzones/Subzones.types';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { Avatar } from '@shared/components/Avatar/Avatar';
import { EMPTY_VALUE } from '@shared/constants/constants';
import { t } from 'i18next';
import s from '@shared/components/ActionDropdownMenu/ActionDropdownMenu.module.scss';

const generateWallToWallSubzoneTableCells = (
  data: WallToWallSubzoneRowType,
  handleMultipleAssignee: (
    index: number,
    layoutDistinctName: string,
    parentLayoutDistinctName: string
  ) => void,
  rowId: number | undefined,
  subZoneId: number | undefined,
  onClickDetailsHandler: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    rowId: number | undefined,
    subzoneIndex: number | undefined
  ) => void
) => {
  const {
    statusCd,
    index,
    subzone,
    timeStarted,
    counter,
    reCounter,
    netAdjusted,
    absolutePCAccuracy,
    pcAdjustments,
    variances,
    locationProgress,
    totalLocations,
    locationsAssigned,
    locationsCompleted,
    layoutDistinctName,
    parentLayoutDistinctName,
  } = data;
  return [
    {
      value: (
        <View>
          <Text weight="bold">{subzone}</Text>
        </View>
      ),
      sortValue: subzone,
    },
    {
      value: timeStarted || EMPTY_VALUE,
      sortValue: timeStarted,
    },
    {
      value:
        counter && counter.length > 0 ? (
          <View>
            {counter.map((name, index) => (
              <Avatar key={index} name={name} size="large" variant="assignee" showText={true} />
            ))}
          </View>
        ) : (
          EMPTY_VALUE
        ),
      sortValue: counter?.length ? counter.join(',') : '',
    },
    {
      value:
        reCounter && reCounter !== EMPTY_VALUE ? (
          <View>
            <Avatar name={reCounter} size="large" variant="assignee" showText={true} />
          </View>
        ) : (
          EMPTY_VALUE
        ),
      sortValue: reCounter,
    },
    {
      value: netAdjusted || EMPTY_VALUE,
      sortValue: netAdjusted,
    },
    {
      value: absolutePCAccuracy || EMPTY_VALUE,
      sortValue: absolutePCAccuracy,
    },
    {
      value: pcAdjustments || EMPTY_VALUE,
      sortValue: pcAdjustments,
    },
    {
      value: variances || EMPTY_VALUE,
      sortValue: variances,
    },
    {
      value:
        locationProgress?.min !== undefined && locationProgress?.max !== undefined ? (
          <View direction="row" align="center" gap={2}>
            <ProgressBar
              label={`${locationProgress.min} / ${locationProgress.max}`}
              value={locationProgress.min}
              max={locationProgress.max}
            />
          </View>
        ) : (
          EMPTY_VALUE
        ),
    },
    {
      value: totalLocations,
      sortValue: totalLocations,
    },
    {
      value: locationsAssigned,
      sortValue: locationsAssigned,
    },
    {
      value: locationsCompleted,
      sortValue: locationsCompleted,
    },
    {
      value: (
        <ActionDropdownMenu>
          <View padding={[1, 0]}>
            <View.Item>
              <View padding={[3, 4]}>
                <Actionable
                  fullWidth
                  onClick={(event) => onClickDetailsHandler(event, rowId, subZoneId)}
                >
                  <Text>{t('CycleCount.WallToWallPhases.Subzone.Actions.ViewSubzoneDetails')}</Text>
                </Actionable>
              </View>
            </View.Item>
            <View.Item>
              <View padding={[3, 4]}>
                <Actionable
                  className={
                    s[
                      statusCd === CYCLE_COUNT_STATUS_CD.COMPLETE
                        ? 'action-dropdown__content--disabled'
                        : ''
                    ]
                  }
                  fullWidth
                  onClick={(
                    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                  ) => {
                    event.stopPropagation();
                    if (statusCd === CYCLE_COUNT_STATUS_CD.COMPLETE) {
                      return;
                    }
                    handleMultipleAssignee(
                      index,
                      layoutDistinctName ?? '',
                      parentLayoutDistinctName ? parentLayoutDistinctName : ''
                    );
                  }}
                >
                  <Text>{t('CycleCount.WallToWallPhases.Subzone.Actions.ManageAssignees')}</Text>
                </Actionable>
              </View>
            </View.Item>
          </View>
        </ActionDropdownMenu>
      ),
    },
  ];
};

export const mapWallToWallSubzoneRows = (
  data: WallToWallSubzoneRowType[] | [],
  handleMultipleAssignee: (
    index: number,
    parentLayoutDistinctName: string,
    layoutDistinctName: string
  ) => void,
  onClickDetailsHandler: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    rowId: number | undefined,
    subzoneIndex: number | undefined
  ) => void
) => {
  return data?.map((item: WallToWallSubzoneRowType, index: number) => {
    return {
      id:
        item.subZoneIndex != undefined
          ? item.index.toString() + '-' + item.subZoneIndex.toString()
          : item.index.toString(),
      cells: generateWallToWallSubzoneTableCells(
        {
          statusCd: item.statusCd,
          index: index,
          subzone: item.subzone,
          timeStarted: item.timeStarted,
          counter: item.counter,
          reCounter: item.reCounter,
          netAdjusted: item.netAdjusted,
          absolutePCAccuracy: item.absolutePCAccuracy,
          pcAdjustments: item.pcAdjustments,
          variances: item.variances,
          locationProgress: item.locationProgress,
          totalLocations: item.totalLocations,
          locationsAssigned: item.locationsAssigned,
          locationsCompleted: item.locationsCompleted,
          layoutDistinctName: item.layoutDistinctName,
          parentLayoutDistinctName: item.parentLayoutDistinctName,
        },
        handleMultipleAssignee,
        item.itemIndex,
        item.subZoneIndex,
        onClickDetailsHandler
      ),
    };
  });
};
